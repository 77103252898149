import React, { useCallback } from 'react';
import { Login } from '../components/login.component';
import { Panel } from '../../common/components/panel/panel.component';
import { login, getUserInfo, getUserAdditionalData } from '../auth.store';
import { getOrganizationsFx } from '../../organization/organization.store';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getCurrenciesFx } from '../../currencies/currencies.store';
import { ACCESS_DENIED_PATH } from '../../accessDenied/access-denied.route';

export type LoginScreenProps = { pageTitle: string };

export const LoginScreen = ({ pageTitle }: LoginScreenProps) => {
  const navigate = useNavigate();

  const navigateAndReload = useCallback(
    (path: string) => {
      navigate(path);
      window.location.reload();
    },
    [navigate],
  );

  const onLogin = (credentials) => {
    login(credentials).then(() => {
      return getUserInfo().then((user) => {
        return getOrganizationsFx({ sort: 'organizationId' }).then(
          async (result) => {
            if (result.items.length) {
              getUserAdditionalData();
              await getCurrenciesFx();

              if (user) {
                if (user.roles?.includes('Receiving'))
                  navigateAndReload(
                    `/org/${result.items[0].organizationId}/v2/warehouseReceipts`,
                  );
                else if (user.roles?.includes('Administrator'))
                  navigateAndReload(
                    `/org/${result.items[0].organizationId}/v2/jobs`,
                  );
                else if (user.roles?.includes('Agent'))
                  navigateAndReload(
                    `/org/${result.items[0].organizationId}/v2/agentParcels`,
                  );
                else if (user.roles?.includes('Fulfillment'))
                  navigateAndReload(
                    `/org/${result.items[0].organizationId}/v2/fulfillment/inventory`,
                  );
                else if (user.roles?.includes('OrgAdmin'))
                  navigate(`/org/${result.items[0].organizationId}/`);
              }
            } else {
              navigate(ACCESS_DENIED_PATH);
            }
          },
        );
      });
    });
  };

  return (
    <div className="py-5">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="d-flex justify-content-center">
        <Panel style={{ maxWidth: '500px', width: '100%' }}>
          <h1 className="text-center login-register-header font-weight-light">
            Sign in
          </h1>
          <Login onLogin={onLogin} />
        </Panel>
      </div>
    </div>
  );
};

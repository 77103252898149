import React, { CSSProperties, useEffect, useState } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { BsCalendar3 } from 'react-icons/bs';
import { clearMessages } from '../../messages.store';
import { get } from 'lodash';

export type ReactDatepickerProps = {
  size?: 'sm' | 'md' | 'lg';
  id?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  readonly?: string;
  style?: CSSProperties;
  className?: string;
  validate?: (value?: string) => Promise<string>;
  secondLabel?: () => any;
  disabled?: boolean;
  defaultValue?: any;
  onChange?: (data: any, context?: any) => void;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  dateFormat?: string;
  timeFormat?: string;
};

export const ReactDatepickerComponent = ({
  size,
  id,
  label,
  placeholder,
  required,
  readonly,
  style,
  className,
  secondLabel,
  disabled,
  defaultValue,
  onChange,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  timeCaption,
  dateFormat,
  timeFormat,
}: ReactDatepickerProps) => {
  const context = useFormikContext();
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(get(context?.values, id));
  }, [context?.values, id]);

  return (
    <>
      <div className={`prevent-drag ${className}`} style={style}>
        {label ? (
          <label className="input-label" htmlFor={id}>
            <span className="d-flex justify-content-between align-items-center">
              <span className="input-label-primary">{label}</span>
              {secondLabel ? (
                <span className="input-label-secondary">{secondLabel}</span>
              ) : null}
            </span>
          </label>
        ) : null}
        <div className="d-flex flex-column">
          <Field required={required} type="hidden" name={id} value={value} />
          <div className="d-flex align-items-center position-relative">
            <DatePicker
              selected={value ? new Date(value) : false}
              className={`form-control form-control-${size} ${id}`}
              onChange={(date) => {
                try {
                  onChange(date);
                } catch {}
                context.setFieldValue(id, date);
              }}
              onCalendarClose={() => context.setFieldTouched(id, true, true)}
              onFocus={clearMessages}
              placeholderText={placeholder}
              readonly={readonly}
              disabled={disabled}
              required={required}
              shouldCloseOnSelect={true}
              showTimeSelect={showTimeSelect}
              showTimeSelectOnly={showTimeSelectOnly}
              timeIntervals={timeIntervals}
              timeCaption={timeCaption}
              dateFormat={dateFormat}
              timeFormat={timeFormat}
              id={`${id}Picker`}
              autoComplete={'off'}
            />
            <label
              htmlFor={`${id}Picker`}
              className="label-datepicker calendar-icon position-absolute"
            >
              <BsCalendar3 />
            </label>
          </div>
          <ErrorMessage
            name={id}
            component="div"
            className="invalid-feedback"
          />
        </div>
      </div>
    </>
  );
};

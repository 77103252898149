import React from 'react';
import { Button } from '../../common/components/button/button.component';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SignUpForm } from './signup.form';
import { resetPassword } from '../auth.store';
import { addMessage, Message } from '../../common/messages.store';
import * as Yup from 'yup';

const initialValues = {
  password: null,
  confirmPassword: null,
};

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string().required("Can't be blank").nullable(true),
  confirmPassword: Yup.string()
    .equals([Yup.ref('password')])
    .required("Can't be blank")
    .nullable(true),
});

export const ResetPasswordComponent = () => {
  // @ts-ignore
  const { userId, code } = useParams();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    resetPassword({ ...data, code, userId }).then(
      (result) => {
        const message: Message = {
          id: 'password-reset',
          type: 'success',
          autoHide: true,
          message:
            'Password has been reset! You can now login using new password.',
        };
        addMessage(message);
        navigate('/login');
      },
      () => {},
    );
  };
  return (
    <div>
      <SignUpForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        id={'ResetPasswordForm'}
        validationSchema={resetPasswordSchema}
      >
        <SignUpForm.Password label={'New Password'} />
        <Button
          name="sign-in"
          type="submit"
          color="primary"
          className="btn-block"
        >
          Reset Password
        </Button>
        <div className="mt-5">
          <div className="d-flex justify-content-center">
            <span>
              <Link to="/login" data-cy="link-sign-up">
                &nbsp;Back to login
              </Link>
            </span>
          </div>
        </div>
      </SignUpForm>
    </div>
  );
};

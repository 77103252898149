import { useCallback, useEffect, useState } from 'react';
import { ComponentProps } from './layout-interfaces';
import { get, isEqual, mapValues, memoize } from 'lodash';
import { queryGraphQlFx } from '../../../graphql/graphql.store';
import moment from 'moment';
import { workflowAction } from '../../context/actions/workflowAction';
import { fromLocalStorage } from './variableHandlers/fromLocalStorage';
import { fromConfig } from './variableHandlers/fromConfig';
import logger from '../../logger';
import { v4 as uuidv4 } from 'uuid';

export const getObj = (obj: any, path: string) => {
  if (path === 'now()') return new Date();
  else if (path === 'timeZone()')
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  return get(obj, path);
};

export const stringDictionary = (val: any) => {
  if (typeof val !== 'object' || val === null || val === undefined) {
    return val;
  }

  return Object.entries(val).reduce((acc, [key, value]) => {
    let strValue: string;

    if (typeof value === 'boolean') {
      strValue = value.toString().toLowerCase();
    } else if (typeof value === 'object' && value !== null) {
      if (value instanceof Date) {
        strValue = value.toISOString();
      } else {
        strValue = JSON.stringify(value);
      }
    } else if (value === null || value === undefined) {
      strValue = null;
    } else {
      strValue = String(value);
    }

    acc[key] = strValue;
    return acc;
  }, {});
};

const anyFunc = (val: any) => {
  return val?.length > 0 === true;
};

const isTrueFunc = (val: any) => {
  if (typeof val === 'string') {
    return val.toLowerCase() === 'true';
  }
  if (val) return true;
  return false;
};

const isNullOrEmpty = (val: any) => {
  if (Array.isArray(val)) {
    return val.length === 0;
  }
  return val === null || val === undefined || val === '';
};

const moreThan = (val: any, compare: any) => {
  return val?.length > compare;
};

const luceneEscape = (value: any) => {
  if (typeof value === 'string') {
    return value.replace(/[\\+\-!(){}\[\]^"~*?:\\/]/g, '\\$&');
  }
  return value;
};

const toLuceneString = (value: any) => {
  if (typeof value === 'string') {
    return `"${luceneEscape(value)}"`;
  } else if (value instanceof Date) {
    return `"${new Date(value).toISOString()}"`;
  } else if (typeof value === 'number') {
    return `"${value}"`;
  } else if (typeof value === 'boolean') {
    return `"${value.toString().toLowerCase()}"`;
  } else if (value === null || value === undefined) {
    return 'NULL';
  }
  return value;
};

export const convertType = (type: string, val: any) => {
  if (type === 'luceneString') {
    return toLuceneString(val);
  }

  if (
    val === null ||
    val === undefined ||
    (val === '' && type?.includes('number'))
  ) {
    if (type === 'boolean') {
      return Boolean(val);
    } else if (type === 'number!') {
      return 0;
    }
    return null;
  }
  if (type?.includes('number')) {
    return Number(val);
  }
  if (type === 'string') {
    return String(val);
  }
  if (type === 'boolean') {
    return toBoolean(val);
  }
  if (type === 'stringDictionary') {
    return stringDictionary(val);
  }

  return val;
};

export const formatValue = (val: any, format?: any, format2?: string) => {
  if (val === null || val === undefined) {
    return null;
  }

  const dateFormats = [
    'MM/DD/YYYY',
    'MM/DD/YYYY HH:mm',
    'MM/DD/YYYY HH:mm:ss',
    'YYYY-MM-DD',
    'DD/MM/YYYY',
    'YYYY/MM/DD',
    'HH:mm:ss',
    'HH:mm:ss A',
    'hh:mm:ss A',
    'hh:mm A',
    'HH:mm',
    'fromNow',
    'L', // 09/04/1986
    'l', // 09/04/1986
    'll', // Sep 4, 1986
    'LLL', // September 4, 1986 8:30 PM
    'LLLL', // Thursday, September 4, 1986 8:30 PM
    'LT', // 8:30 PM
    'LTS', // 8:30:25 PM
  ];

  const numberFormats: Record<string, Intl.NumberFormatOptions> = {
    '0,0.00': {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    },
    // Add other number formats as needed
    '0,0': { useGrouping: true },
    '0.00': { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    '0': {},
    '0.00%': {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    '0%': { style: 'percent' },
    currency: {
      style: 'currency',
      currency: format2,
    },
  };

  if (dateFormats.includes(format)) {
    if (val === '') return '';
    if (format === 'fromNow') {
      return moment(val).fromNow();
    }
    const fullFormat = format2 ? `${format} ${format2}` : format;
    return moment(val).format(fullFormat);
  }

  const numberFormatOptions = numberFormats[format];
  if (numberFormatOptions) {
    const formattedVal = new Intl.NumberFormat(
      'en-US',
      numberFormatOptions,
    ).format(val);
    return formattedVal;
  }
  return val;
};

export const hasPermission = (
  permissionName: string | undefined | null,
  variables: any,
) => {
  if (!permissionName) return true;

  if (variables?.currentUser?.permissionSet) {
    return variables.currentUser.permissionSet.has(permissionName);
  }

  return false;
};

// Memoize the function creation to avoid recreating functions for the same expression
const createExpressionFunction = memoize(
  (expression: string, hasDeclarations: boolean) => {
    if (hasDeclarations) {
      return new Function('variables', `with(variables){${expression}}`);
    } else {
      return new Function('variables', `with(variables){return ${expression}}`);
    }
  },
);

export const evaluateExpression = (expression: string, variables: any) => {
  try {
    // Replace [formName] only if it exists in the expression
    if (expression.includes('[formName]')) {
      expression = expression.replace(
        /\[formName\]/g,
        variables['formName'] || '',
      );
    }

    const hasDeclarations =
      expression.includes('let ') || expression.includes('const ');

    // Create or retrieve the memoized function
    const expressionFunction = createExpressionFunction(
      expression,
      hasDeclarations,
    );

    // Execute the function with the variables
    return expressionFunction({ ...variables, uuidv4 });
  } catch (e) {
    // check if error is is not defined
    if (e instanceof Error && e.message.includes('is not defined')) {
      return null;
    }

    logger.error(`Error evaluating expression "${expression}"`, e, variables);
    return null;
  }
};

export const toBoolean = (val: any) => {
  if (typeof val === 'string') {
    return val?.toLowerCase() === 'true';
  }
  if (!val) return false;
  return Boolean(val);
};

export const parseTemplateScalar = (
  val: any,
  variables: any,
  prefix?: any,
): any => {
  if (typeof val === 'string') {
    // test for single variable mapping {{ variable }}
    const match = val.match(/{{(.*?)}}/g);
    if (match?.length === 1) {
      // check if variable starts and ends with {{ }}
      if (val.startsWith('{{') && val.endsWith('}}')) {
        const mappedField = match[0]
          .replace('{{', '')
          .replace('}}', '')
          .trim()
          .split(' ');

        const isNegated = mappedField[0]?.startsWith('!');
        if (isNegated) {
          mappedField[0] = mappedField[0].substring(1);
        }

        if (mappedField.length > 1) {
          const operation = mappedField[0];

          if (operation === 'eval') {
            const expression = mappedField.slice(1).join(' ');
            const result = evaluateExpression(expression, variables);
            return isNegated ? !result : result;
          } else if (operation === 'hasPermission') {
            const permission = mappedField.slice(1).join(' ');
            const has = hasPermission(permission, variables);
            return isNegated ? !has : has;
          } else if (operation === 'format') {
            return formatValue(
              getObj(variables, (prefix ?? '') + mappedField[1]),
              mappedField.slice(2, 3).join(' '),
              mappedField.slice(3).join(' '),
            );
          } else if (operation === 'encodeURIComponent') {
            return encodeURIComponent(
              getObj(variables, (prefix ?? '') + mappedField[1]),
            );
          } else if (operation === 'round') {
            const decimals = parseInt(mappedField[2], 10) || 0;
            return Number(
              getObj(variables, (prefix ?? '') + mappedField[1]).toFixed(
                decimals,
              ),
            );
          } else if (operation === 'localStorage') {
            const localVal = fromLocalStorage(mappedField[1], variables);
            return isNegated ? !toBoolean(localVal) : localVal;
          } else if (operation === 'fromConfig') {
            return fromConfig(mappedField[1], mappedField[2], mappedField[3]);
          } else if (operation === 'any') {
            const localVal = anyFunc(
              getObj(variables, (prefix ?? '') + mappedField[1]),
            );
            return isNegated ? !localVal : localVal;
          } else if (operation === 'isNullOrEmpty') {
            const localVal = isNullOrEmpty(
              getObj(variables, (prefix ?? '') + mappedField[1]),
            );
            return isNegated ? !localVal : localVal;
          } else if (operation === 'isEqual') {
            const localVal = isEqual(
              getObj(variables, (prefix ?? '') + mappedField[1]) ??
                mappedField[1],
              getObj(variables, (prefix ?? '') + mappedField[2]) ??
                mappedField[2],
            );
            return isNegated ? !localVal : localVal;
          } else if (operation === 'moreThan') {
            const localVal = moreThan(
              getObj(variables, (prefix ?? '') + mappedField[1]),
              mappedField[2],
            );
            return isNegated ? !localVal : localVal;
          } else if (operation === 'trim') {
            return getObj(variables, (prefix ?? '') + mappedField[1]).trim();
          } else if (operation === 'isTrue') {
            return isTrueFunc(
              getObj(variables, (prefix ?? '') + mappedField[1]),
            );
          }

          const convertedValue = convertType(
            operation,
            getObj(variables, (prefix ?? '') + mappedField[1]),
          );

          return isNegated ? !convertedValue : convertedValue;
        }

        let getVal = getObj(variables, (prefix ?? '') + mappedField[0]);
        if (
          (getVal === null || getVal === undefined) &&
          mappedField[0].includes('configs') &&
          variables.hasOwnProperty('configs') &&
          variables.configs
        ) {
          let configName = '';
          Object.keys(variables.configs).forEach((key) => {
            if (mappedField[0].includes(key)) {
              configName = key;
            }
          });
          const pathArray = mappedField[0].split(`.${configName}.`);
          getVal = getObj(variables, [pathArray[0], configName, pathArray[1]]);
        }
        return isNegated ? !getVal : getVal;
      }
      return val
        .replace(/{{(.*?)}}/g, (_: string, p1: string) => {
          const p1Trim = p1.trim();
          let internalPath = (prefix ?? '') + p1Trim;
          if (p1Trim.startsWith('~')) {
            internalPath = p1Trim.substring(1);
          }
          if (p1Trim.includes(' ')) {
            const parts = p1Trim.split(' ');
            if (parts.length > 1) {
              internalPath = (prefix ?? '') + parts[1];
              return convertType(parts[0], getObj(variables, internalPath));
            }
          }
          const valItem = getObj(variables, internalPath);
          return valItem ?? '';
        })
        .trim();
    } else {
      // Replace all instances of template variables
      return val
        .replace(/{{(.*?)}}/g, (_: string, p1: string) => {
          const p1Trim = p1.trim();
          let internalPath = (prefix ?? '') + p1Trim;
          if (p1Trim.startsWith('~')) {
            internalPath = p1Trim.substring(1);
          }
          if (p1Trim.includes(' ')) {
            const parts = p1Trim.split(' ');
            if (parts.length > 1) {
              internalPath = (prefix ?? '') + parts[1];
              return convertType(parts[0], getObj(variables, internalPath));
            }
          }
          const valItem = getObj(variables, internalPath);
          return valItem ?? '';
        })
        .trim();
    }
  }
  return val;
};

/// Parse object template, does not parse values
export const parseObjectTemplate = (obj: any, variables: any) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => parseObjectTemplate(item, variables));
  }

  if (typeof obj === 'object') {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      let keyName = key;
      if (key.includes('{{') && key.includes('}}')) {
        keyName = parseTemplate(key, variables);
      }
      acc[keyName] = parseObjectTemplate(value, variables);
      return acc;
    }, {});
  }

  return obj;
};

export const parseTemplate = (
  value: any,
  variables: any,
  prefix?: any,
): any => {
  const _prefix = prefix ?? '';
  if (!value) {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map((item) => parseTemplate(item, variables, _prefix));
  }

  if (typeof value === 'string') {
    return parseTemplateScalar(value, variables, _prefix);
  }

  if (value instanceof Date) {
    const timezoneOffset = value.getTimezoneOffset() * 60000;
    return new Date(value.getTime() - timezoneOffset).toISOString();
  }

  // object has fromParams
  if (value.fromParams) {
    return getObj(variables.params, value.fromParams);
  }

  if (typeof value === 'object') {
    const newVariables = {};

    if (value?.expression) {
      return evaluateExpression(value.expression, variables);
    }

    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        // array manipulation, if property is array

        let templatedKey = key;
        if (key.includes('{{') && key.includes('}}')) {
          templatedKey = parseTemplate(key, variables, _prefix);
        }

        // if newKey starts with ~, it is a root level variable
        let path = `${_prefix}${key}`;
        if (key.startsWith('~')) {
          path = key.substring(1);
        }
        if (path.includes('{{') && path.includes('}}')) {
          path = parseTemplate(path, variables, _prefix);
        }
        const variableValue = variables ? getObj(variables, path) : null;

        if (variables && isArrayAction(value[key])) {
          const arrayAction = value[key];
          if (arrayAction.append) {
            // append to array
            const appendValue = parseTemplate(
              arrayAction.append,
              variables,
              _prefix,
            ); // parse append value
            newVariables[templatedKey] = [...(variableValue ?? [])]; // copy array
            if (appendValue) {
              newVariables[templatedKey].push(...appendValue); // append value
            }
          } // remove from array
          else if (arrayAction.remove) {
            const removeValue = parseTemplate(
              arrayAction.remove,
              variables,
              _prefix,
            ); // parse remove value
            newVariables[templatedKey] = (variableValue ?? []).filter(
              (item) => {
                // match item with remove value object
                for (const removeItem of removeValue) {
                  if (isDeepMatch(removeItem, item)) {
                    return false;
                  }
                }
                return true;
              },
            ); // remove value
          } else if (arrayAction.removeByIndex) {
            const indexesToRemove = Object.values(
              arrayAction.removeByIndex,
            ).map((value) => parseTemplate(value, { ...variables }));
            newVariables[templatedKey] = (variableValue ?? []).filter(
              (_, index) => !indexesToRemove.includes(index),
            );
          } else if (arrayAction.update?.value && arrayAction.update.idName) {
            // update array element
            const updateValue = parseTemplate(
              arrayAction.update.value,
              variables,
              _prefix,
            );
            newVariables[templatedKey] = (variableValue ?? []).map((item) => {
              if (
                getObj(updateValue, arrayAction.update.idName) ===
                getObj(item, arrayAction.update.idName)
              ) {
                item = updateValue; // update value
              }
              return item;
            });
          } else {
            newVariables[templatedKey] = parseTemplate(
              value[key],
              variables,
              _prefix,
            );
          }
        } else {
          // in case we have an item which is on the top level of variables (for example selectedItem)
          if (
            typeof value[templatedKey] === 'string' &&
            variables?.hasOwnProperty(
              value[key].replace('{{', '').replace('}}', '').trim(),
            )
          ) {
            newVariables[templatedKey] = parseTemplate(value[key], variables);
          } else {
            newVariables[templatedKey] = parseTemplate(
              value[key],
              variables,
              _prefix,
            );
          }
        }
      }
    }
    return newVariables;
  }

  return value;
};

export const parseKeys = (obj: any, variables: any) => {
  return obj.map((x) => {
    // TODO: Check creatable component
    if (x.if) {
      return { ...x };
    }
    return Object.entries(x).map((y) => {
      const tempResult = Object.entries(y[1]).map((z) => {
        const parsedKey = parseTemplate(z[0], variables);
        return { [parsedKey]: z[1] };
      });
      const newData = {};
      tempResult.forEach((item) =>
        Object.entries(item).forEach((entry) => (newData[entry[0]] = entry[1])),
      );
      return { [y[0]]: newData };
    });
  });
};

export const nullsToEmpty = (value: any) => {
  if (typeof value === 'object' && !(value instanceof Date)) {
    const dataWithoutNulls = mapValues(value, (x) => nullsToEmpty(x ?? ''));
    if (Array.isArray(value)) {
      return Object.values(dataWithoutNulls);
    } else {
      return dataWithoutNulls;
    }
  } else {
    return value ?? '';
  }
};

export const isArrayAction = (value: any) => {
  if (value?.append || value?.remove || value?.removeByIndex || value?.update) {
    return true;
  }
  return false;
};

export const isDeepMatch = (obj1: any, obj2: any) => {
  if (obj1 === obj2) {
    return true;
  }
  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 == null ||
    obj2 == null
  ) {
    return false;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    const sortedObj1 = [...obj1].sort();
    const sortedObj2 = [...obj2].sort();
    return sortedObj1.every((value, index) =>
      isDeepMatch(value, sortedObj2[index]),
    );
  }

  const keysA = Object.keys(obj1);
  for (const key of keysA) {
    if (!(key in obj2)) {
      return false; // Key not found in obj2, therefore not equal
    }
    if (!isDeepMatch(obj1[key], obj2[key])) {
      return false; // Deep compare values under the same key in both objects
    }
  }
  // No need to check keys of obj2 since extra properties are ignored
  return true;
};

export const useComponentVariables = (
  props: ComponentProps,
  additionalVariables?: any,
) => {
  const [variables, setVariables] = useState({
    ...props.variables,
    ...additionalVariables,
  });

  useEffect(() => {
    if (props?.variables) {
      // check if variables changed using deep comparison
      if (!isDeepMatch(variables, props.variables)) {
        setVariables((prevState) => {
          return {
            ...prevState,
            ...props.variables,
          };
        });
      }
    }
  }, [props.variables]);

  // set default values for inputs
  useEffect(() => {
    if (props?.inputs?.length > 0) {
      props.inputs.forEach((inputDef) => {
        setVariables((prevState) => {
          const inputDefault = parseTemplate(
            inputDef.props?.defaultValue,
            prevState,
          );
          // Only update if doesn't exist
          if (prevState[inputDef.name] === undefined) {
            return {
              ...prevState,
              [inputDef.name]: inputDefault,
            };
          }
          return prevState;
        });
      });
    }
  }, [props.inputs]);

  return { variables, setVariables };
};

export const useComponentQueries = (props: ComponentProps, variables: any) => {
  const [queries, setQueries] = useState(null);

  useEffect(() => {
    if (props?.props?.queries?.length > 0) {
      const queryMap = props.props.queries.reduce((acc: any, queryDef: any) => {
        return {
          ...acc,
          [queryDef.name]: queryDef,
        };
      }, {});

      setQueries(queryMap);
    }
  }, [props?.props?.queries]);

  const query = useCallback(
    async (queryName: string, params: any) => {
      if (queries?.[queryName]) {
        const q = queries[queryName]?.query;
        if (q) {
          const vars = parseTemplate(q.variables, { ...variables, ...params });
          try {
            const res = await queryGraphQlFx({
              query: q.command,
              variables: vars,
            });
            if (q.transform) {
              return parseTemplate(q.transform, res);
            }
            if (q.path) {
              return getObj(res, q.path);
            }
            return res;
          } catch (e) {
            logger.error(`Query ${queryName} failed`, e);
            return null;
          }
        }
        // workflow
        const workflow = queries[queryName]?.workflow;
        if (workflow) {
          const inputs = parseTemplate(workflow.inputs, {
            ...variables,
            ...params,
          });
          try {
            const result = await workflowAction({
              actionProps: {
                workflow: {
                  workflowId: workflow.workflowId,
                  inputs,
                  onSuccess: workflow.onSuccess,
                  onError: workflow.onError,
                },
              },
              data: {
                ...variables,
                ...params,
              },
              latestStoreValues: variables,
              source: null,
              onAction: props.context.action,
            });

            return result;
          } catch (e) {
            logger.error(`Workflow ${queryName} failed`, e);
            return null;
          }
        }

        throw new Error(`Query ${queryName} is not valid`);
      } else {
        throw new Error(`Query ${queryName} not found`);
      }
    },
    [queries],
  );

  const getPropertyValue = useCallback(
    async (
      property: any,
      defaultValue: any = null,
      additionalParams: any = null,
    ) => {
      if (!property) {
        return defaultValue;
      }
      // check if property has query name
      if (property.fromQuery) {
        const fromQuery = property.fromQuery;
        const queryName = fromQuery.name ?? fromQuery;

        const params =
          parseTemplate(fromQuery.params, {
            ...variables,
            ...additionalParams,
          }) ?? {};

        let queryResult = await query(queryName, {
          ...params,
          ...additionalParams,
        });
        if (fromQuery.path) {
          queryResult = getObj(queryResult, fromQuery.path);
        }

        if (property.mapping) {
          queryResult = {
            ...queryResult,
            ...parseTemplate(property.mapping, variables),
          };
        }

        return queryResult;
      }

      if (property.mapping) {
        // check if property has mapping template
        return parseTemplate(property.mapping, variables);
      }
      return property;
    },
    [query, variables],
  );

  return { query, queries, getPropertyValue };
};

// ILocalize type should be string or key-value pair keys are locale and values are string
export type ILocalizeString = string | { [key: string]: string } | null;

export const localized = (value: ILocalizeString): string | null => {
  if (!value) {
    return null;
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return value;
  }
  // en-US
  const locale = 'en-US';
  if (value[locale]) {
    return value[locale];
  }

  // return first value
  return Object.values(value)[0];
};
